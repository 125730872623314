<template>
  <v-main class="main">
    <loader :loading="$auth.loading" />
    <trainer-header :links="links" :finishRedirect="{ name: 'courseDetails', params: this.$route.params }" />
    <v-container>
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
    <dialogs-container />
    <snackbars-container />
  </v-main>
</template>

<script>
import Loader from "@/components/base/Loader.vue";
import TrainerHeader from "@/components/trainer/TrainerHeader.vue";
import DialogsContainer from "@/components/dialog/DialogsContainer.vue";
import SnackbarsContainer from "@/components/snackbar/SnackbarsContainer.vue";

export default {
  name: "Trainer",
  components: {
    SnackbarsContainer,
    DialogsContainer,
    TrainerHeader,
    Loader,
  },
  computed: {
    links() {
      const basicLinks = [];
      return basicLinks;
    },
  },
};
</script>
